import React, { Component } from 'react';
import $ from 'jquery';
import { ajax_wrapper } from 'functions';
import { Modal, TextInput, Select, Form } from 'library';
import { TagButton } from 'components';
import { sort_objects } from 'functions';

export default class NewTaxonomyTag extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && !prevProps.show) {
            setTimeout(
                function () {
                    $('.new-tagger-input input').focus();
                }.bind(this),
                100,
            );
        }
    }

    submit(state) {
        console.log(state);
        this.props.add_tag({ tag_name: state.name, parent: state.parent });
    }

    render() {
        let parent_options = this.props.parents.map((item) => ({
            text: item.name,
            value: item.id,
        }));

        // for (let item of this.props.data.users) {
        //     user_options.push({ text: item.email, value: item.id });
        // }

        return (
            <div key={new Date().getTime()}>
                <Modal show={this.props.show} on_hide={this.props.on_hide}>
                    <div className="simple-card-container">
                        <div className="simple-card">
                            <h2>Add New Tag</h2>

                            <div className="form-narrow">
                                <Form
                                    defaults={{
                                        parent: this.props.parent
                                            ? this.props.parent.id
                                            : '',
                                    }}
                                    submit={this.submit}
                                    submit_text="Add Tag"
                                >
                                    <TextInput
                                        name="name"
                                        label="Name"
                                        required={true}
                                    />
                                    <Select
                                        name="parent"
                                        label={`Parent ${this.props.tag_type.slice(0, -1)}`}
                                        options={parent_options}
                                        required={true}
                                    />
                                    <br />
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

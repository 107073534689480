import React, { Component } from 'react';

import { ajax_wrapper, sort_objects } from 'functions';

class CheckBox extends Component {
    static component_name = 'CheckBox';

    constructor(props) {
        super(props);
        this.state = { checked: this.get_value() };
    }

    handle_change = (e) => {
        let checked = e.target.checked;
        let new_state = {};
        new_state[this.props.name] = checked;
        this.props.set_form_state(new_state);
    };

    get_value = () => {
        return typeof this.props.value === 'undefined'
            ? false
            : this.props.value;
    };

    render() {
        let label = null;
        if (this.props.label) {
            label = (
                <label style={this.props.label_style}>{this.props.label}</label>
            );
        }

        return (
            <div
                className={`form-group ${this.props.className}`}
                style={this.props.style}
            >
                {label}
                <div>
                    <input
                        type="checkbox"
                        className="form-checkbox"
                        name={this.props.name}
                        onChange={this.handle_change}
                        checked={this.get_value()}
                    />
                </div>
            </div>
        );
    }
}

export default CheckBox;

import React, { Component } from 'react';

import { ajax_wrapper, sort_objects } from 'functions';
import { Button } from 'library';

class Selection extends Component {
    render() {
        return (
            <span onClick={(e) => this.props.onClick(e, this.props.value)}>
                <span
                    style={{ display: 'inline-block' }}
                    className={this.props.className}
                >
                    {this.props.name}
                </span>
            </span>
        );
    }
}

class ItemPoolSelection extends Component {
    itemPool_onClick = (e) => {
        this.props.onClick(e, this.props.value);
    };

    render() {
        return (
            <Button
                type="primary"
                onClick={this.itemPool_onClick}
                className={this.props.className}
                style={{ cursor: 'pointer' }}
            >
                {this.props.name}
            </Button>
        );
    }
}

class MultiSelect extends Component {
    static component_name = 'Select';

    constructor(props) {
        super(props);
        this.state = { options: [] };

        this.refresh_data = this.refresh_data.bind(this);
        this.options_callback = this.options_callback.bind(this);
        this.handle_change = this.handle_change.bind(this);
        this.get_value = this.get_value.bind(this);
        this.remove_selection = this.remove_selection.bind(this);

        this.render_select = this.render_select.bind(this);
        this.render_checkbox = this.render_checkbox.bind(this);
        this.render_item_pool = this.render_item_pool.bind(this);

        this.ref = React.createRef();
    }

    componentDidMount() {
        this.refresh_data();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let select_all = () => {
            let new_state = {};
            new_state[this.props.name] = this.props.options.map(
                (option) => option.value,
            );
            this.props.set_form_state(new_state);
        };

        if (this.props.options_url !== prevProps.options_url) {
            this.refresh_data();
        }

        if (this.props.options !== prevProps.options) {
            if (this.props.item_pool && this.props.select_all) {
                select_all();
            }
        }
    }

    refresh_data() {
        if (this.props.options_url && this.props.options_url !== '') {
            ajax_wrapper(
                'GET',
                this.props.options_url,
                {},
                this.options_callback.bind(this),
            );
        }
    }

    options_callback(value) {
        const options = [];
        for (const index in value) {
            options.push({
                text: value[index]['name'],
                value: value[index]['id'],
            });
        }

        sort_objects(options, ['text']);

        this.setState({ options });
    }

    get_value() {
        return typeof this.props.value === 'undefined' ? [] : this.props.value;
    }

    remove_selection(event, selection) {
        const value = this.get_value();
        const index = value.indexOf(selection);
        value.splice(index, 1);

        const newState = {};
        newState[this.props.name] = value;
        this.props.set_form_state(newState);
    }

    clear_all = () => {
        const newState = {};
        newState[this.props.name] = [];
        this.props.set_form_state(newState);
    };

    handle_change = (e) => {
        let new_state = {};
        const selection = e.target.value;

        const value = this.get_value();

        const index = value.indexOf(selection);
        if (index === -1) {
            value.push(selection);
        } else {
            value.splice(index, 1);
        }

        new_state[this.props.name] = value;
        this.props.set_form_state(new_state);

        if (this.ref.current) {
            this.ref.current.value = '';
        }
    };

    render_select(options, options_dict, value, label) {
        const options_jsx = [<option key={-1} value="" selected="selected" />];

        // Create JSX for select options
        for (let item of options) {
            options_jsx.push(
                <option
                    key={`${this.props.name}-${item['value']}`}
                    value={item['value']}
                >
                    {item['text']}
                </option>,
            );

            options_dict[item['value']] = item['text'];
        }

        var selections = [];
        for (let item of value) {
            selections.push(
                <Selection
                    key={`${this.props.name}-${item}-selected`}
                    className="multiselect-selection"
                    onClick={this.remove_selection}
                    value={item}
                    name={options_dict[item]}
                />,
            );
        }

        return (
            <div
                className={`form-group multiselect ${this.props.className}`}
                style={this.props.style}
            >
                {label}
                <div>
                    <select
                        ref={this.ref}
                        className="form-control"
                        name={this.props.name}
                        onChange={this.handle_change}
                        value={value}
                    >
                        {options_jsx}
                    </select>
                </div>

                <div style={{ display: 'inline-block' }}>{selections}</div>
                <div style={{ width: '100%', clear: 'both' }} />
            </div>
        );
    }

    render_checkbox(options, options_dict, value, label) {
        let options_jsx = [];

        const type = 'btn-outline-secondary';

        for (let item of options) {
            let button_style = {
                border: 'thin solid',
                display: 'inline-block',
            };
            var active = ' ';
            button_style['backgroundColor'] = 'rgba(5,5,5,.2)';
            if (value.indexOf(item['value']) > -1) {
                active = ' active';
                button_style['backgroundColor'] = 'rgba(5,150,5,.6)';
            }

            options_jsx.push(
                <label className={`btn ${type}${active}`} style={button_style}>
                    <input
                        style={{ display: 'none' }}
                        type="checkbox"
                        name={this.props.name}
                        key={`${this.props.name}-${item}`}
                        value={item['value']}
                        onClick={this.handle_change}
                    />
                    {item['text']}
                </label>,
            );
        }

        return (
            <div className={`checkbox ${this.props.className}`}>
                {options_jsx}
            </div>
        );
    }

    render_item_pool(options, options_dict, value, label, style_dict) {
        let pool_jsx = [];
        const options_jsx = [<option key={-1} value="" selected="selected" />];

        for (let item of options) {
            options_jsx.push(
                <option
                    key={`${this.props.name}-${item['value']}`}
                    value={item['value']}
                >
                    {item['text']}
                </option>,
            );

            options_dict[item['value']] = item['text'];
        }

        for (let item of value) {
            pool_jsx.push(
                <ItemPoolSelection
                    key={`${this.props.name}-${item}-selected`}
                    onClick={this.remove_selection}
                    name={options_dict[item]}
                    value={item}
                    style={{}}
                    className={style_dict[item]}
                />,
            );
        }

        return (
            <div>
                {this.props.label}
                <select
                    ref={this.ref}
                    style={{
                        marginLeft: '10px',
                        marginBottom: '4px',
                        width: '70%',
                        backgroundColor: 'rgba(255,255,255,1)',
                        borderRadius: '5px',
                    }}
                    name={this.props.name}
                    onChange={this.handle_change}
                    value={value}
                >
                    {options_jsx}
                </select>
                <div className="simple-item-pool">
                    {pool_jsx}

                    {/*<Button type="primary" className="dark-button" style={{margin: "4px"}}>+</Button>*/}
                </div>
            </div>
        );
    }

    render() {
        let label = null;
        if (this.props.label) {
            label = (
                <label style={this.props.label_style}>{this.props.label}</label>
            );
        }

        let value = this.get_value();

        const options = this.props.options_url
            ? this.state.options
            : this.props.options;

        // Render Select Component
        const options_dict = {};
        const style_dict = {};
        for (let item of options) {
            options_dict[item['value']] = item['text'];
            style_dict[item['value']] = item['class']
                ? item['class']
                : 'item-pool-item';
        }

        let content = this.render_select(options, options_dict, value, label);
        if (this.props.checkbox) {
            content = this.render_checkbox(options, options_dict, value, label);
        } else if (this.props.item_pool) {
            content = this.render_item_pool(
                options,
                options_dict,
                value,
                label,
                style_dict,
            );
        }

        return content;
    }
}

export default MultiSelect;

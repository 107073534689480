import React, { Component } from 'react';

import { ajax_wrapper, get_global_state } from 'functions';
import { Link, Container, Button, Loading } from 'library';

class Item extends Component {
    render() {
        return (
            <div className="simple-card">
                <Link
                    href={`/admin/${this.props.modelname}/${this.props.data['id']}`}
                >
                    {this.props.data['name']}
                </Link>
            </div>
        );
    }
}

export default class ObjectList extends Component {
    constructor(props) {
        super(props);

        let params = get_global_state()['params'];

        let app = params['app'] ? params['app'] : this.props.app;
        let modelname = params['modelname']
            ? params['modelname']
            : this.props.modelname;

        this.state = {
            app: app,
            modelname: modelname,
            loaded: false,
            list: [],
        };

        this.load_list = this.load_list.bind(this);
    }

    componentDidMount() {
        let user = get_global_state()['user'];

        let url = `/api/${this.state.app}/${this.state.modelname}/`;
        if (user['role'] == 'owner') {
            url += `${this.props.scoping}${user['id']}`;
        }

        ajax_wrapper('GET', url, {}, this.load_list);
    }

    load_list(value) {
        this.setState({ loaded: true, list: value });
    }

    render() {
        let object_list = [];
        for (let item of this.state.list) {
            object_list.push(
                <Item data={item} modelname={this.state.modelname} />,
            );
        }

        let new_url = `/admin/${this.state.modelname}/`;
        if (this.state.modelname === 'taxonomy') {
            new_url = `/admin/import_taxonomy/`;
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <h2 style={{ fontWeight: 'light' }}>
                        {`${this.state.modelname}s`.toUpperCase()}
                    </h2>
                    <div>
                        <Button type="primary" href={new_url}>
                            Add New
                        </Button>
                    </div>
                    <div className="simple-card-container">{object_list}</div>
                </Loading>
            </Container>
        );
    }
}

import React, { Component } from 'react';

import { Container, Loading, MultiSelect, Form } from 'library';
import { UserValidator, ajax_wrapper, get_global_state } from 'functions';
import { DropboxExplorer } from 'components';

export default class CreateSync extends Component {
    constructor(props) {
        super(props);

        let checker = new UserValidator();

        let params = get_global_state()['params'];
        let project_id = params['id'] ? params['id'] : this.props.project_id;

        this.state = {
            loaded: false,
            data: {},
            ml_options: [],
            project_id: project_id,

            is_staff: checker.is_staff(),

            selected_files: [],
            selected_folder: [],
            features: ['bpm', 'key', 'ml_analyze'],
        };
    }

    componentDidMount() {
        ajax_wrapper('GET', `/api/home/mlmodel/`, {}, (value) =>
            this.setState({ ml_options: value }),
        );

        if (this.state.project_id) {
            this.refresh_project();
        } else {
            this.setState({ loaded: true });
        }

        if (this.state.dropbox_creds) {
            this.search();
        }
    }

    refresh_project = () => {
        ajax_wrapper(
            'GET',
            `/api/home/project/${this.state.project_id}/`,
            {},
            (value) => this.setState({ loaded: true, data: value }),
        );
    };

    submit = (state) => {
        let project_id = this.state.data.id;
        var folder_name = state.selected_folder.join('/');

        let url = `/api/create_dropbox_sync/${project_id}/`;
        let data = {
            folder_name: folder_name,
            files: state.selected_files,
            bpm: state.features.includes('bpm'),
            ml_analyze: state.features.includes('ml_analyze'),
            key: state.features.includes('key'),
            ml_selections: state.ml_selections ? state.ml_selections : [],
        };

        ajax_wrapper(
            'POST',
            url,
            data,
            function (value) {
                if ('error' in value) {
                    this.setState({ error: value['error'] });
                } else {
                    let split_num = value['split_redirect'];
                    window.location.href = `/project/${project_id}/${split_num}/`;
                }
            }.bind(this),
        );
    };

    render() {
        let ml_options = [];
        for (let ml_model of this.state.ml_options) {
            ml_options.push({ text: ml_model.name, value: ml_model.id });
        }

        let display_ml = ml_options.length > 0;

        let select_options = [
            { text: 'BPM Analyzer', value: 'bpm' },
            { text: 'Key Finder', value: 'key' },
        ];

        if (display_ml) {
            select_options.push({
                text: 'Machine Learning',
                value: 'ml_analyze',
            });
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div
                        className="simple-card-container"
                        style={{ marginBottom: '80px' }}
                    >
                        <div className="simple-card">
                            <div class="page-header">
                                <h1>{`Add A New Songs To Project: ${this.state.data.name}`}</h1>
                            </div>
                        </div>

                        <Form
                            defaults={{
                                features: this.state.features,
                                selected_files: this.state.selected_files,
                                selected_folder: this.state.selected_folder,
                            }}
                            submit={this.submit}
                            submit_text="Import Selected Files"
                            submit_button_type="success"
                            buttons_className="simple-card fixed-floating-card-bottom"
                        >
                            <MultiSelect
                                className="simple-card"
                                checkbox
                                name="features"
                                label="Features"
                                options={select_options}
                            />

                            <div
                                className="simple-card"
                                style={{
                                    display: display_ml ? 'block' : 'none',
                                }}
                            >
                                <h5>ML Models</h5>
                                <MultiSelect
                                    checkbox
                                    name="ml_selections"
                                    label="Available ML:"
                                    options={ml_options}
                                />
                            </div>

                            <DropboxExplorer
                                files_name="selected_files"
                                folder_name="selected_folder"
                                redirect_url={`project/${this.state.data.id}/add_songs`}
                            />
                        </Form>
                    </div>
                </Loading>
            </Container>
        );
    }
}

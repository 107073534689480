import React, { Component } from 'react';

import { Form, TextInput, Container, FileInput, MultiSelect } from 'library';
import {
    get_global_state,
    export_taxonomy_template,
    ajax_wrapper,
} from 'functions';

export default class ImportTaxonomy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            special_fields: [],
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper('GET', `/api/get_special_fields/`, {}, (value) => {
            let special_fields = [];
            for (let field of value) {
                special_fields.push({
                    text: field.display_name,
                    value: field.name,
                });
            }
            this.setState({
                special_fields: special_fields,
            });
        });
    }

    render() {
        return (
            <Container>
                <div className="simple-card-container">
                    <div className="simple-card">
                        <h3>New Taxonomy:</h3>
                        <a
                            onClick={() => export_taxonomy_template()}
                            style={{
                                fontStyle: 'italic',
                                textDecoration: 'underline',
                            }}
                        >
                            Download Template Here
                        </a>
                    </div>

                    <div className="simple-card">
                        <Form
                            defaults={this.state.data}
                            submit_url={`/api/import_taxonomy/`}
                            redirect={function () {
                                window.location.href = '/admin/home/taxonomy/';
                            }}
                        >
                            <TextInput
                                name="name"
                                label="Name"
                                required={true}
                            />
                            <br />
                            <FileInput
                                name="file"
                                label="File"
                                required={true}
                            />
                            <h4>Special Fields:</h4>
                            <MultiSelect
                                name="special_fields"
                                label="Special Fields"
                                required={false}
                                options={this.state.special_fields}
                                checkbox={true}
                            />
                            <br />
                            <br />
                        </Form>
                    </div>
                </div>
            </Container>
        );
    }
}

import React, { Component, ScrollView } from 'react';
import { swap_dict, create_dict_from_listofdict } from 'functions';

import {
    Form,
    TextInput,
    FileInput,
    Container,
    Loading,
    Select,
    CheckBox,
    MultiSelect,
    Button,
} from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

export default class ManageExportFormat extends Component {
    constructor(props) {
        super(props);

        let params = get_global_state()['params'];

        let url = params['id']
            ? `/api/home/exportformat/${params['id']}/`
            : `/api/home/exportformat/`;

        this.state = {
            url: url,
            loaded: false,
            tag_types_loaded: false,
            data: null,
            existing_fields: [],
            taxonomy: '',
            taxonomy_tagtypes_lookup: {},
            special_fields: [],
            available_fields: [],
            number_of_fields: 20,
            headers_locked: false,
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper('GET', `/api/export_format_defaults/`, {}, (value) =>
            this.setState({
                special_fields: value['special_fields'].map((x) => ({
                    text: x,
                    value: x,
                })),
            }),
        );

        if (params['id']) {
            ajax_wrapper(
                'GET',
                this.state.url,
                {},
                function (value) {
                    if (!('exportformat' in value) && params['exportformat']) {
                        value['exportformat'] = params['exportformat'];
                    }
                    this.setState(
                        { data: value, loaded: true },
                        console.log('Value:', value),
                    );

                    ajax_wrapper(
                        'GET',
                        `/api/home/exportfield/?export_format=${params['id']}&only_fields=id,tag_types,special_type,order,sub_order,header`,
                        {},
                        this.load_previous_fields,
                    );
                }.bind(this),
            );
        } else {
            this.setState({ loaded: true });
        }

        ajax_wrapper(
            'GET',
            '/api/get_related/home/tagtype/',
            {},
            function (value) {
                let taxonomy_tagtypes = this.define_tag_types(value);
                this.setState({
                    taxonomy_tagtypes_lookup: taxonomy_tagtypes,
                    tag_types_loaded: true,
                });
            }.bind(this),
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.state.taxonomy !== '' &&
            this.state.taxonomy !== prevState.taxonomy
        ) {
            let tag_type_options = this.state.available_fields;

            let tag_type_lookup = this.state.taxonomy_tagtypes_lookup
                ? this.state.taxonomy_tagtypes_lookup[this.state.taxonomy]
                : [];

            for (let tag_type of tag_type_lookup) {
                tag_type_options.push({
                    text: tag_type['name'],
                    value: tag_type['id'],
                });
            }
            this.setState({
                available_fields: tag_type_options,
            });
        }
    }

    delete_self = () => {
        ajax_wrapper(
            'DELETE',
            `/api/delete_export_format/${this.state.id}/`,
            {},
            (value) => (window.location = `/admin/home/exportformat/`),
        );
    };

    load_previous_fields = (value) => {
        let num_fields = value.length;

        let previous_state = {
            existing_fields: value,
            number_of_fields: num_fields,
            loaded: true,
        };

        for (let field of value) {
            let field_key = `field_${field['order']}`;
            let field_header_key = `field_${field['order']}_label`;
            if (field['sub_order'] === 0) {
                previous_state[field_key] = [];
            }

            if (field['special_type']) {
                previous_state[field_key].push(field['special_type']);
            } else {
                for (let tag_type of field['tag_types']) {
                    previous_state[field_key].push(tag_type['id']);
                }
            }

            previous_state[field_header_key] = field['header'];
        }

        this.setState(previous_state);
    };

    define_tag_types = (value) => {
        let taxonomy_tagtypes = {};
        for (let tag_type of value) {
            let taxonomy_id = tag_type.taxonomy;
            if (taxonomy_id in taxonomy_tagtypes) {
                taxonomy_tagtypes[taxonomy_id].push(tag_type);
            } else {
                taxonomy_tagtypes[taxonomy_id] = [tag_type];
            }
        }
        return taxonomy_tagtypes;
    };

    update_headers = (state) => {
        if (!this.state.headers_locked) {
            for (let key in state) {
                if (!key.includes('field_')) {
                    continue;
                }
                if (key.includes('_label')) {
                    continue;
                }

                const tag_types = state[key];
                const header_names = [];

                for (let duple of this.state.available_fields) {
                    if (tag_types.includes(duple['value'])) {
                        header_names.push(duple['text']);
                    }
                }

                for (let duple of this.state.special_fields) {
                    if (tag_types.includes(duple['value'])) {
                        header_names.push(duple['text']);
                    }
                }

                state[`${key}_label`] = header_names.join(', ');
            }
            this.setState(state);
        }
    };

    update_csv_headers = (value) => {
        let new_state = { ...this.state, headers_locked: true };
        let tagtype_name_lookup = create_dict_from_listofdict(
            this.state.available_fields,
            'text',
            'value',
        );
        let special_fields_lookup = create_dict_from_listofdict(
            this.state.special_fields,
            'text',
            'value',
        );

        let field_count = value['headers'].length;
        new_state['number_of_fields'] = field_count;

        for (let i of [...Array(field_count).keys()]) {
            new_state[`field_${i}_label`] = value['headers'][i];
            if (
                Object.keys(tagtype_name_lookup).includes(value['headers'][i])
            ) {
                new_state[`field_${i}`] = [
                    tagtype_name_lookup[value['headers'][i]],
                ];
            } else if (
                Object.keys(special_fields_lookup).includes(value['headers'][i])
            ) {
                new_state[`field_${i}`] = [value['headers'][i]];
            } else if (
                Object.keys(tagtype_name_lookup).includes(
                    value['headers'][i] + 's',
                )
            ) {
                new_state[`field_${i}`] = [
                    tagtype_name_lookup[value['headers'][i] + 's'],
                ];
            }
        }

        this.setState(new_state);
    };

    submit = (form_state) => {
        let params = get_global_state()['params'];

        let url = '';
        if (params['id'] === undefined) {
            url = `/api/edit_export_format/`;
        } else {
            url = `/api/edit_export_format/${params['id']}/`;
        }

        let parentheticals = form_state.include_parentheticals;
        if (typeof form_state.include_parentheticals === 'undefined') {
            parentheticals = false;
        }

        let data = {
            name: form_state.name,
            taxonomy: form_state.taxonomy,
            prefix: form_state.prefix,
            include_parentheticals: parentheticals,
        };

        for (let i of [...Array(this.state.number_of_fields).keys()]) {
            data[`field_${i}_label`] = this.state[`field_${i}_label`]
                ? this.state[`field_${i}_label`]
                : '';
            data[`field_${i}`] = this.state[`field_${i}`]
                ? this.state[`field_${i}`]
                : '';
        }

        data['number_of_fields'] = this.state.number_of_fields;

        ajax_wrapper('POST', url, data, (value) => {
            window.location = `/admin/home/exportformat/`;
        });
    };

    render() {
        let user = get_global_state()['user'];
        let params = get_global_state()['params'];

        let field_options = [];

        for (let field of this.state.special_fields) {
            field_options.push(field);
        }

        for (let field of this.state.available_fields) {
            field_options.push(field);
        }

        let field_number_options = [];
        for (let i of [...Array(30).keys()]) {
            let j = i * 5;
            field_number_options.push({ text: j, value: j });
        }

        var delete_button = '';
        if (!this.state.id == '') {
            delete_button = (
                <Button
                    type="danger"
                    style={{ float: 'right' }}
                    onClick={this.delete_self}
                >
                    Delete
                </Button>
            );
        }

        let fields_jsx = [];
        let first = true;
        for (let i of [
            ...Array(parseInt(this.state.number_of_fields)).keys(),
        ]) {
            let column_letter = '';
            if (i < 26) {
                column_letter = String.fromCharCode(97 + i).toUpperCase();
            } else {
                let j = Math.floor(i / 26) - 1;
                let k = i % 26;
                column_letter =
                    String.fromCharCode(97 + j).toUpperCase() +
                    String.fromCharCode(97 + k).toUpperCase();
            }
            if (first) {
                first = false;
            }
            fields_jsx.push(
                <div
                    key={`column_${i}`}
                    style={{
                        width: '22%',
                        display: 'inline-block',
                        margin: '4px',
                        verticalAlign: 'top',
                    }}
                >
                    <TextInput
                        name={`field_${i}_label`}
                        value={this.state[`field_${i}_label`]}
                        style={{
                            color: 'white',
                            marginLeft: '10px',
                            backgroundColor: 'rgba(0,0,0,0)',
                            fontSize: '20px',
                            border: '0px',
                        }}
                        placeholder={`Column ${column_letter}`}
                    />
                    <MultiSelect
                        name={`field_${i}`}
                        options={field_options}
                        value={this.state[`field_${i}`]}
                    />
                </div>,
            );
            if ((i + 1) % 4 === 0) {
                fields_jsx.push(<br />);
                fields_jsx.push(<br />);
            }
        }

        let submit_text = 'Create New Export Format';
        if (params['id']) {
            submit_text = 'Update Export Format';
        }

        return (
            <Container>
                <Loading
                    loaded={this.state.loaded && this.state.tag_types_loaded}
                >
                    <div className="simple-card-container">
                        <div className="simple-card">
                            <h3 style={{ display: 'inline-block' }}>
                                New Export Format
                            </h3>
                            {delete_button}
                            <br />
                            <div>
                                <p
                                    style={{
                                        fontStyle: 'italic',
                                        display: 'inline-block',
                                        marginRight: '10px',
                                    }}
                                ></p>
                                <Form
                                    submit_text="Update Headers"
                                    submit_url="/api/get_csv_headers/"
                                    redirect={this.update_csv_headers}
                                >
                                    <FileInput
                                        name="file"
                                        label="Upload CSV Headers:"
                                    />
                                </Form>
                            </div>
                            <Form
                                set_global_state={(state_name, state) =>
                                    this.update_headers(state)
                                }
                                auto_set_global_state={true}
                                submit={this.submit}
                                submit_text={submit_text}
                                defaults={this.state.data}
                            >
                                <TextInput
                                    name="name"
                                    label="Name"
                                    required={true}
                                />
                                <br />
                                <table>
                                    <tr>
                                        <td style={{ paddingRight: '15px' }}>
                                            <TextInput
                                                name="prefix"
                                                label="Filename Prefix:"
                                                style={{ minWidth: '20%' }}
                                            />
                                        </td>
                                        <td>
                                            <Select
                                                style={{
                                                    display: 'inline-block',
                                                }}
                                                name="number_of_fields"
                                                label="Number of Fields"
                                                options={field_number_options}
                                            />
                                        </td>
                                        <td>
                                            <CheckBox
                                                style={{
                                                    margin: '15px',
                                                    marginBottom: '30px',
                                                }}
                                                name="include_parentheticals"
                                                label="Include Parentheses? Dramatic (Horror) etc. "
                                            />
                                        </td>
                                    </tr>
                                </table>

                                {user['role'] == 'admin' ? (
                                    <Select
                                        name="taxonomy"
                                        label="Taxonomy"
                                        style={{ width: '75%' }}
                                        options_url="/api/home/taxonomy/"
                                        required={true}
                                    />
                                ) : (
                                    <Select
                                        name="taxonomy"
                                        label="Taxonomy"
                                        style={{ width: '75%' }}
                                        options_url={`/api/home/taxonomy/?clients__owners=${user['id']}`}
                                        required={true}
                                    />
                                )}

                                <br />

                                <h6
                                    style={{
                                        fontWeight: 'lighter',
                                        letterSpacing: '.5px',
                                        fontStyle: 'italic',
                                    }}
                                >
                                    **Note: Field names default to the selected
                                    tag name unless overwritten
                                </h6>
                                {fields_jsx}
                            </Form>
                        </div>
                    </div>
                </Loading>
            </Container>
        );
    }
}

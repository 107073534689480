import React, { Component } from 'react';

import {
    Form,
    TextInput,
    Container,
    Loading,
    Select,
    MultiSelect,
    Button,
} from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

export default class ManageClient extends Component {
    constructor(props) {
        super(props);

        let params = get_global_state()['params'];
        let url = params['id']
            ? `/api/home/client/${params['id']}/`
            : `/api/home/client/`;

        this.state = {
            url: url,
            loaded: false,
            data: {},
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];
        let user = get_global_state()['user'];

        if (params['id']) {
            ajax_wrapper(
                'GET',
                this.state.url,
                {},
                function (value) {
                    let new_owners = [];
                    for (let item of value['owners']) {
                        new_owners.push(item['id']);
                    }
                    value['owners'] = new_owners;

                    this.setState({ data: value }, () =>
                        ajax_wrapper(
                            'GET',
                            `/api/home/taxonomy/?clients=${params['id']}`,
                            {},
                            function (value) {
                                let data = this.state.data;
                                data['taxonomies'] = [];
                                for (let item of value) {
                                    data['taxonomies'].push(item['id']);
                                }
                                this.setState({
                                    loaded: true,
                                    data: data,
                                    id: params['id'],
                                });
                            }.bind(this),
                        ),
                    );
                }.bind(this),
            );
        } else {
            this.setState({ loaded: true, data: { owners: [user.id] } });
        }
    }

    delete_self = () => {
        console.log('deleted');
        ajax_wrapper(
            'DELETE',
            `/api/delete_client/${this.state.id}/`,
            {},
            (value) => (window.location = `/admin/home/client/`),
        );
    };

    render() {
        let user = get_global_state()['user'];

        var delete_button = '';
        if (!this.state.id == '') {
            delete_button = (
                <Button
                    type="danger"
                    style={{ float: 'right' }}
                    onClick={this.delete_self}
                >
                    Delete
                </Button>
            );
        }
        // Limit defaults to editable values only
        let defaults = {
            name: this.state.data['name'],
            owners: this.state.data['owners'],
            taxonomies: this.state.data['taxonomies'],
        };

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div className="simple-card-container">
                        <div className="simple-card">
                            {delete_button}
                            <h3>Client:</h3>
                            <Form
                                defaults={defaults}
                                submit_url={this.state.url}
                                redirect={function () {
                                    window.location.href =
                                        '/admin/home/client/';
                                }}
                            >
                                <TextInput name="name" label="Name" />
                                {user['role'] == 'admin' ? (
                                    <div>
                                        <MultiSelect
                                            name="owners"
                                            label="Owners"
                                            options_url="/api/user/users/"
                                        />
                                        <MultiSelect
                                            name="taxonomies"
                                            label="Taxonomies"
                                            options_url="/api/home/taxonomy/"
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <Select
                                            name="owner"
                                            label="Owner"
                                            options={[
                                                {
                                                    text: user['email'],
                                                    value: user['id'],
                                                },
                                            ]}
                                        />
                                        <MultiSelect
                                            name="taxonomies"
                                            label="Taxonomies"
                                            options_url={`/api/home/taxonomy/?clients__owners=${user['id']}`}
                                        />
                                    </div>
                                )}

                                <br />
                            </Form>
                        </div>
                    </div>
                </Loading>
            </Container>
        );
    }
}

import React, { Component } from 'react';

import { ajax_wrapper, get_global_state } from 'functions';
import { AdminCard } from 'components';
import { Container, Loading, Link } from 'library';

export default class AdminDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        };
    }

    render() {
        let user = get_global_state()['user'];

        let options = [
            {
                // LIST OF USERS WITH INFO AND DROPDOWNS
                name: 'Manage Taggers',
                url: 'manage_taggers',
                roles: ['owner', 'admin'],
                helper_text:
                    'Assign projects, view completion stats and payouts, send user messages.',
            },
            {
                name: 'Client Options',
                url: 'admin/home/client',
                roles: ['owner', 'admin'],
                helper_text:
                    'Assign taxonomy, enable/disable ML, view track quotas',
            },
            {
                name: 'Edit Taxonomy',
                url: 'admin/home/taxonomy',
                roles: ['owner', 'admin'],
                helper_text: 'Import and edit Taxonomies',
            },
            {
                // ADD GALLERY VIEW
                name: 'Manage ML Models',
                url: 'admin/manage_ml',
                roles: ['admin'],
                helper_text: 'Import models, manage mappings, view settings',
            },
            {
                // ORGANIZE BY CLIENT + ADD FILTERS
                name: 'See Projects',
                url: 'admin/manage_projects',
                roles: ['admin'],
                helper_text:
                    'View completed projects, change ownership, edit details',
            },
            {
                // BUILDER UI
                name: 'Edit Export Formats',
                url: 'admin/home/exportformat',
                roles: ['owner', 'admin'],
                helper_text:
                    'Create new export templates for projects, clients, and taxonomies',
            },
            {
                // BUILDER UI
                name: 'UI Layouts',
                url: 'admin/manage_UI',
                roles: ['admin'],
                helper_text:
                    'Build new tagging layouts, assign to projects/clients',
            },
            {
                name: 'Review Dropbox Syncs',
                url: 'review_dropbox_syncs',
                roles: ['owner', 'admin'],
                helper_text: 'View status of all dropbox syncs',
            },
            {
                name: 'Send David A Meme',
                url: 'admin/spam_david',
                roles: ['admin'],
                helper_text:
                    'Design script to email David something ridiculous from the internet',
            },
        ];

        let options_cards = [];

        for (let item of options) {
            if (!item['roles'].includes(user['role'])) {
                continue;
            }

            options_cards.push(<AdminCard data={item} />);
        }

        return (
            <Container>
                <div>
                    <h2>Admin Dashboard</h2>
                </div>
                <div>
                    <div className="simple-card-container flex-container">
                        {options_cards}
                    </div>
                    <br />
                    {user['role'] == 'admin' ? (
                        <div>
                            <h4>Models</h4>

                            <div className="simple-card-container">
                                <Link
                                    className="simple-card"
                                    href="/admin/home/taxonomy/"
                                >
                                    Taxonomy
                                </Link>
                                <Link
                                    className="simple-card"
                                    href="/admin/home/tagtype/"
                                >
                                    Tag Types
                                </Link>
                                <Link
                                    className="simple-card"
                                    href="/admin/home/client/"
                                >
                                    Client
                                </Link>
                                <Link
                                    className="simple-card"
                                    href="/admin/home/project/"
                                >
                                    Project
                                </Link>
                                <Link
                                    className="simple-card"
                                    href="/admin/home/exportformat/"
                                >
                                    ExportFormat
                                </Link>
                                <Link
                                    className="simple-card"
                                    href="/admin/home/uilayout/"
                                >
                                    UILayout
                                </Link>
                                <Link
                                    className="simple-card"
                                    href="/admin/home/mlmodel/"
                                >
                                    MLModel
                                </Link>
                            </div>
                        </div>
                    ) : null}
                </div>
            </Container>
        );
    }
}
